export default {
  computed: {
    isCartConfirmed() {
      console.log('please implement computed `#isCartConfirmed` in component.')
    },

    currentOrderItems() {
      if (this.isCartConfirmed || !this.selectedShippingMethod) return []

      return this.$store.getters['orderItems/fromCurrentOrder']()
    },

    cartVariants() {
      return this.currentOrderItems.map((item) =>
        this.$store.getters['productVariants/find'](item.variant_id)
      )
    },

    products() {
      return this.$store.getters['products/all']
    },

    partners() {
      return this.$store.getters['partners/all']
    },

    shippingMethods() {
      return this.$store.getters['shippingMethods/all']
    },

    selectedShippingMethod() {
      return this.shippingMethods.find(
        (method) => method.id === this.selectedShippingMethodId
      )
    },

    partnerShippingMethodShips() {
      return this.$store.getters['partnerShippingMethodShips/all']
    },

    selectedPartnerShippingMethodShips() {
      if (this.isCartConfirmed || !this.selectedShippingMethod) return []

      return this.partnerShippingMethodShips.filter(
        (partnerShippingMethodShip) => {
          return (
            partnerShippingMethodShip.shipping_method_id ===
            parseInt(this.selectedShippingMethod.id)
          )
        }
      )
    },

    selectedPartnerShippingMethodShips() {
      if (this.isCartConfirmed || !this.selectedShippingMethod) return []

      return this.partnerShippingMethodShips.filter(
        (partnerShippingMethodShip) => {
          return (
            partnerShippingMethodShip.shipping_method_id ===
            parseInt(this.selectedShippingMethod.id)
          )
        }
      )
    },

    calculatingShipmentTotal() {
      if (this.isCartConfirmed || !this.selectedShippingMethod) return

      let rateType = this.selectedShippingMethod.rate_type
      switch (rateType) {
        case 'flat':
          return this.toMoney(
            this.selectedPartnerShippingMethodShips.reduce((result, ship) => {
              return result + parseInt(ship.rate_rule.price) * 100
            }, 0)
          ).format()
        case 'flat_per_item':
          return this.toMoney(
            this.currentOrderItems.reduce((result, cartItem) => {
              return result + this.findShipmentTotalFromCartItem(cartItem)
            }, 0)
          ).format()
      }
    },

    calculatingShipmentTotalNote() {
      if (this.isCartConfirmed || !this.selectedShippingMethod) return null

      let partnersCount = this.selectedPartnerShippingMethodShips.length
      return `共${partnersCount}間廠商出貨，預估運費 TWD ${this.calculatingShipmentTotal}`
    }
  },

  methods: {
    findShipmentTotalFromCartItem(cartItem) {
      let variant = this.cartVariants.find((variant) => {
        return parseInt(variant.id) === parseInt(cartItem.variant_id)
      })
      let product = this.products.find((product) => {
        return parseInt(product.id) === variant.product_id
      })
      let partner = this.partners.find((partner) => {
        return parseInt(partner.id) === product.partner_id
      })
      let partnerShippingMethodShip =
        this.selectedPartnerShippingMethodShips.find((ship) => {
          return parseInt(partner.id) === ship.partner_id
        })
      return (
        parseInt(partnerShippingMethodShip.rate_rule.price) *
        100 *
        cartItem.quantity
      )
    }
  }
}
